<script setup lang="ts">
import { MainApi } from '~/net/apis/main_api'

const HomeStore = useHome()
const router = useRouter()
const groupId = useRouteParam<number>('groupId') ?? 1
const onClickLeft = () => history.back()
console.log('HomeStore', HomeStore.groupNameStatus)
</script>

<template>
  <div>
    <van-nav-bar :title="HomeStore.groupName" left-arrow @click-left="onClickLeft" />
      <PullRefreshList
      wrap-class="w-full grid grid-cols-2 gap2 px-15px"
      :request-api="MainApi.videoComAll"
      :offset-list="10"
      :request-params="{
        pageSize: 20,
        groupId
      }"
    >
      <template #="{ data }">
        <MediaBaseCard :item="data as CommonFilmModel" />
      </template>
    </PullRefreshList>
  </div>
</template>

<style scoped>
:deep(.van-pull-refresh){
  height: calc(100vh - 50px);
}
</style>
